<template>
  <div class="app">
<!--    <remote_nav></remote_nav>-->
    <div class="content" >
      <div class="param-container" style="display: none">
        <remote_info_input
            label="userId" @change="handleValueChange($event, 'userId')"></remote_info_input>
        <remote_info_input
            label="roomId" type="number" @change="handleValueChange($event, 'roomId')"></remote_info_input>
        <remote_info_input
            label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></remote_info_input>
        <remote_info_input
            label="secretKey" @change="handleValueChange($event, 'secretKey')"></remote_info_input>
      </div>
      <p class="label" style="display: none">设备选择</p>
      <div class="param-container" style="display: none">
        <remote_device
            deviceType="camera" @change="handleValueChange($event, 'cameraId')"></remote_device>
        <remote_device
            deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></remote_device>
      </div>
      <remoteRoom
          :sdkAppId="Number(sdkAppId)"
          :secretKey="secretKey"
          :userId="userId"
          :roomId="Number(roomId)"
          :cameraId="cameraId"
          :microphoneId="microphoneId"></remoteRoom>
    </div>
  </div>
</template>

<script>
import remote_nav from "@/components/remote_nav";
import remote_info_input from "@/components/remote_info_input";
import remote_device from "@/components/remote_device";
import remoteRoom from "@/components/remote_room";
import { clearUrlParam } from '@/utils/utils';

export default {
  name: "remote_treatment_doc",
  components: {
    remoteRoom,
    remote_device,
    remote_info_input,
  },
  data() {
    return {
      sdkAppId: 0,
      secretKey: '',
      userId: '',
      roomId: 0,
      cameraId: '',
      microphoneId: '',
    };
  },
  methods:{
    handleValueChange(value, key) {
      this[key] = value;
    },
  },
  mounted() {
    // clearUrlParam();
  },
};
</script>

<style lang="scss" scoped>

/*.label {
  margin: 10px 0 6px;
  text-align: left;
  font-weight: bold;
}
.param-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    width: calc((100% - 20px) / 2);
    margin-bottom: 10px;
  }
  div:nth-last-child(2), div:nth-last-child(1) {
    margin-bottom: 0;
  }
  &.param-container-mobile {
    div {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}*/

.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  .content {
    width: 100%;
    margin: 0 auto;
    //max-width: 1320px;
    .alert {
      padding-top: 20px;
      font-size: 16px !important;
    }
    &.content-mobile {
      width: 100%;
      padding: 0 16px 20px;
    }
    .label {
      margin: 5px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>